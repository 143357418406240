import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import LinkList from '../../components/LinkList/LinkList'

const UsefulLinksPage = () => {
  const intl = useIntl()
  return (
    <Layout
      breadcrumb={{
        text: <FormattedMessage id='nav.about' />,
        path: '/about/'
      }}
      header={<FormattedMessage id='nav.links' />}
    >
      <SEO
        description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.links' })}
      />
      <Container>
        <Row>
          <Col md={12}>
            {intl.locale === 'en' ? (
              <LinkList
                links={[
                  {
                    path: 'https://www.tarion.com/',
                    text: 'Tarion Warranty Corporation',
                    description: (
                      <FormattedMessage id='linkNames.tarionDescript' />
                    )
                  },
                  {
                    path: 'https://www.newhomeombuds.ca/',
                    text: 'New Home Ombuds',
                    description: (
                      <FormattedMessage id='linkNames.ombudsDescript' />
                    )
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.cbaLink' }),
                    text: <FormattedMessage id='linkNames.cba' />,
                    description: <FormattedMessage id='linkNames.cbaDescript' />
                  },
                  {
                    path: 'https://www.cmhc-schl.gc.ca/',
                    text: 'Canadian Mortgage and Housing Corporation',
                    description:
                      "Canada Mortgage and Housing Corporation (CMHC) offers a helpful booklet called Consumer's Guide to Buying a Home. CMHC's website also dedicates an entire section geared to home buyers."
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.cpoLink' }),
                    text: <FormattedMessage id='linkNames.cpo' />,
                    description: <FormattedMessage id='linkNames.cpoDescript' />
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.lsoLink' }),
                    text: <FormattedMessage id='linkNames.lso' />,
                    description: <FormattedMessage id='linkNames.lsoDescript' />
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.recoLink' }),
                    text: <FormattedMessage id='linkNames.reco' />,
                    description: (
                      <FormattedMessage id='linkNames.recoDescript' />
                    )
                  }
                ]}
              />
            ) : (
              <LinkList
                links={[
                  {
                    path: 'https://www.tarion.com/',
                    text: 'Tarion Warranty Corporation',
                    description: (
                      <FormattedMessage id='linkNames.tarionDescript' />
                    )
                  },
                  {
                    path: 'https://www.newhomeombuds.ca/',
                    text: 'New Home Ombuds',
                    description: (
                      <FormattedMessage id='linkNames.ombudsDescript' />
                    )
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.cbaLink' }),
                    text: <FormattedMessage id='linkNames.cba' />,
                    description: <FormattedMessage id='linkNames.cbaDescript' />
                  },
                  // {
                  //   path: "https://www.cmhc-schl.gc.ca/",
                  //   text: "Canadian Mortgage and Housing Corporation",
                  //   description: "Canada Mortgage and Housing Corporation (CMHC) offers a helpful booklet called Consumer's Guide to Buying a Home. CMHC's website also dedicates an entire section geared to home buyers."
                  // },
                  {
                    path: intl.formatMessage({ id: 'linkNames.cpoLink' }),
                    text: <FormattedMessage id='linkNames.cpo' />,
                    description: <FormattedMessage id='linkNames.cpoDescript' />
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.lsoLink' }),
                    text: <FormattedMessage id='linkNames.lso' />,
                    description: <FormattedMessage id='linkNames.lsoDescript' />
                  },
                  {
                    path: intl.formatMessage({ id: 'linkNames.recoLink' }),
                    text: <FormattedMessage id='linkNames.reco' />,
                    description: (
                      <FormattedMessage id='linkNames.recoDescript' />
                    )
                  }
                ]}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default UsefulLinksPage
